function getSession () {
  const session = useCookie('luser').value
  if (session) {
    return JSON.parse(JSON.stringify(session))
  }

  return null
}

function login (user: LoginUser, expiresHour?: number) {
  const date = new Date()
  if (expiresHour) {
    date.setHours(date.getHours() + expiresHour)
  } else {
    date.setHours(date.getHours() + 24 * 7)
  }
  useCookie('luser', {
    expires: date,
    sameSite: 'strict'
  }).value = JSON.stringify(user)
}

function clear () {
  useCookie('luser').value = null
}

export default function () {
  return {
    get: () => getSession(),
    save: (u: LoginUser, expires? :number) => login(u, expires),
    clear: () => clear()
  }
}
